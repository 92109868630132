@import url(https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Montserrat:wght@100;400;500;600;700;800;900&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
    height: 100%;
    min-height: 1500px;
    width: 100%;
    position: relative;
    font-family:'Montserrat'
}
p {
    margin:0px;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.left {
    float:left;
}
.right {
    float:right;
}
button {
    background-color:#ADEFD1;
    padding: 10px;
    background-position: center;
    font-family: 'Montserrat';
    width:100px;
    font-weight:700;
    border: 0px;
    border-radius: 3px;
    color: #00203F;
    box-shadow: 0px 0px 24px -14px #10c6b7;
}
button:hover {
    color:#10C6B7;
    font-weight:700;
    transition:.2s;
}
.active-screen {
    width:734px;
    min-width:734px;
    max-width:734px;
    min-height:1000px;
}

.secondary-panel {
    width:40%;
    padding:15px;
    position:relative;
    display:inline-block;
}
.main-name-heading {
    font-size:40px;
    color:#00203F;
}

/* Montserrat main font */
/* Cursive font */
/* cyrillic-ext */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nYivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nRivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nWivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nbivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nVivNm4I81.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.capch {
    display:none;
}
.cursive {
    font-family: 'Cedarville Cursive', cursive;
}

.tagline {
    margin-top:10px;
    position: absolute;
    top: 24px;
    left: 522px;
}


.editorContainer {
    position: absolute;
}

#cm6CodeWrapper .cm-scroller,
#cm6CodeWrapper .cm-editor {
    background-color: rgba(255, 255, 255, 0);
}

.cm-arcade-matched-text::before {
    position: absolute;
    top: 0;
    left: 0;
    content:attr(data-letter);
    display: flex;
    color: #39ff14;
    -webkit-animation: up 2s infinite linear;
            animation: up 2s infinite linear;
}

@-webkit-keyframes up {
    0% {
        -webkit-transform: translate(0,0) skew(0);
                transform: translate(0,0) skew(0);
    }
    20% {
        -webkit-transform: translate(0,-40%) skew(-5deg);
                transform: translate(0,-40%) skew(-5deg);
    }
    40% {
        -webkit-transform: translate(0,-80%) skew(8deg);
                transform: translate(0,-80%) skew(8deg);
    }
    60% {
        -webkit-transform: translate(0,-120%) skew(-7deg);
                transform: translate(0,-120%) skew(-7deg);
    }
    80% {
        -webkit-transform: translate(0,-160%) skew(4deg);
                transform: translate(0,-160%) skew(4deg);
    }
    100% {
        -webkit-transform: translate(0,-200%) skew(0);
                transform: translate(0,-200%) skew(0);
        opacity: 0;
    }
}

@keyframes up {
    0% {
        -webkit-transform: translate(0,0) skew(0);
                transform: translate(0,0) skew(0);
    }
    20% {
        -webkit-transform: translate(0,-40%) skew(-5deg);
                transform: translate(0,-40%) skew(-5deg);
    }
    40% {
        -webkit-transform: translate(0,-80%) skew(8deg);
                transform: translate(0,-80%) skew(8deg);
    }
    60% {
        -webkit-transform: translate(0,-120%) skew(-7deg);
                transform: translate(0,-120%) skew(-7deg);
    }
    80% {
        -webkit-transform: translate(0,-160%) skew(4deg);
                transform: translate(0,-160%) skew(4deg);
    }
    100% {
        -webkit-transform: translate(0,-200%) skew(0);
                transform: translate(0,-200%) skew(0);
        opacity: 0;
    }
}

.PongBall_pongBall__2BsKe{
    color:#39ff14;
}
.mainAppWrapper { /*main screen wrapper */
    margin:0 auto;
    display:flex;

}
.uiWrapper {
    position: absolute;
    top: 480px;
    display: flex;
    align-items: center;
}
.metrics {
    position:absolute;
    top:580px;
    display:flex;
}

.flexItem {
    padding:20px;
    min-width:120px;
}
.flexItem.flexItemLarge button{
    padding:20px;
}
.flexItem.first {
    padding-left:0px;
}
.editorscreen-wrapper {
    position:relative;
}
.terminal-wrapper {
    position:fixed;
    top:100px;
    left:0px;
    height:586px;
    width:736px; /*total width - 2px for border dotted #eaeaea;*/
   /* border-right:2px dotted #eaeaea; */
}

.mainWrapper { /*Manager related */
    position: fixed;
    top:200px; /*put these into a constant */
    left:40px; /* put these into a constant */
}

.App-header {
    position:fixed;
    width:736px;
    display:inline-block;
    height:100px; /*Important for game math.*/
    padding:0px 40px; /*Important for game math.*/
    border-bottom: 2px dotted #eaeaea;
}
.top {
    height:100px;
}
.modalWrapper {
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:10;
    min-height:1500px;
    height:100%;
    width:100%;
    align-items: center;
    justify-content: center;
}

.modalScreen {
    position: relative;
    display: inline-block;
    height:100%;
    width:100%;
    align-items: center;
    justify-content: center;
    background:rgba(0,0,0,.7);
}

.modal {
    box-shadow:0px 0px 30px 10px;
    border-radius:2px;
    padding:40px;
    position: fixed;
    top:calc(50% - 200px);
    left:calc(50% - 200px);
    height:464px;
    width:440px;
    align-items: center;
    justify-content: center;
    background:#fff;
}
button.modalClose {
    position: absolute;
    right: 40px;
    width: 36px;
}
.alternateModalLink {
    position:absolute;
    bottom:40px;
}
.buttons {
    margin-top:20px;
}

.form label, .form input {
    float: left;
    clear: both;
    width: 100%;
}
.form label {
    width:25%;
}

.form label {
    font-size: 10px;
    text-transform: capitalize;
    color:#878787;
    display:inline-block;
    margin-bottom:5px;
    margin-top:5px;
}
.modalFormHeader h1 {
    margin-top:0px;
}
.signUpForm button {
    margin-right:10px;
}
.formError {
    display:inline-block;
    color:red;
    font-size:10px;
}
.formError::before {
    content:'*';
}
.form div {
    height:44px;
}
.app-footer {
    position:absolute;
    bottom:200px;
    left:736px;
    /*@TODO: fix the -200px to CONSTANT)*/
}

/*.secondary-panel {
    margin-top:136px;
}*/
.maps .levelCompletionCheck {
    align-self: center;
}
.maps .MuiAccordionSummary-content{
    display:flex;
    align-content: center;
}
.maps span, .maps p {
    display:inline-block;
}
.maps .item-level-name {
    font-size:14px;
    margin-left:5px;
}
.levelInstructionList{
    list-style-type:none;
    padding:20px;
    font-size:20px;
    margin:0px;
}
.levelText {
    position:absolute;
    padding:20px;
    top: calc(50% + 14px);
    background:#ADBEEF;
    height:50%;
    font-size:16px;
    width:100%;
}
.instruction {
    float: left;
    background: #fff;
    padding: 5px 15px;
    margin: 10px 7px;
}
.maps .MuiTypography-root {
    align-self: center;
    color:#00203F;
}

.maps .activeLevel {
    margin-top:100px;
}

.header-profile-img {
    width:78px;
    height:78px;
}
.header-profile-img-wrapper {
    border:1px solid #00203F;
    border-radius:50%;
    overflow:hidden;
    width:70px;
    height:70px;
    background:#00203F;
    text-align:center;
}
.header-profile-email {
    font-size:8px;
    letter-spacing:1px;
}
.profile-img {
}
.profile-email {
}
.left-profile-wrapper {
    float:left;
}
.menu-login-wrapper {
    float:left;
    width:calc(100% - 120px);
    display:flex;
    align-items:center;
    height:100%;
    flex-wrap:nowrap;
    margin-left:40px;
}
.menu-login-wrapper button {
    font-size:10px;
    /* MINT_GREEN  */
    background:#ADEFD1;
    /* SAILOR_BLUE  */
    color:#00203F;
}
.menu-login-wrapper button:hover {
    color:#00203F;
    background:#FFF;
}

.secondary-header-panel {
    width:calc(100% - 736px);
    display:flex;
    float:right;
    height:100px;
    padding:15px;
    background-color:#fff;
    z-index:2;
    border-bottom:2px dotted #eaeaea;
    border-left:2px dotted #eaeaea;
    position:fixed;
    left:734px;
}
