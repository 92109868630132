.editorContainer {
    position: absolute;
}

#cm6CodeWrapper .cm-scroller,
#cm6CodeWrapper .cm-editor {
    background-color: rgba(255, 255, 255, 0);
}

.cm-arcade-matched-text::before {
    position: absolute;
    top: 0;
    left: 0;
    content:attr(data-letter);
    display: flex;
    color: #39ff14;
    animation: up 2s infinite linear;
}

@keyframes up {
    0% {
        transform: translate(0,0) skew(0);
    }
    20% {
        transform: translate(0,-40%) skew(-5deg);
    }
    40% {
        transform: translate(0,-80%) skew(8deg);
    }
    60% {
        transform: translate(0,-120%) skew(-7deg);
    }
    80% {
        transform: translate(0,-160%) skew(4deg);
    }
    100% {
        transform: translate(0,-200%) skew(0);
        opacity: 0;
    }
}