.editorscreen-wrapper {
    position:relative;
}
.terminal-wrapper {
    position:fixed;
    top:100px;
    left:0px;
    height:586px;
    width:736px; /*total width - 2px for border dotted #eaeaea;*/
   /* border-right:2px dotted #eaeaea; */
}

.mainWrapper { /*Manager related */
    position: fixed;
    top:200px; /*put these into a constant */
    left:40px; /* put these into a constant */
}
