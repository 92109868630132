.modalWrapper {
    position: absolute;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:10;
    min-height:1500px;
    height:100%;
    width:100%;
    align-items: center;
    justify-content: center;
}

.modalScreen {
    position: relative;
    display: inline-block;
    height:100%;
    width:100%;
    align-items: center;
    justify-content: center;
    background:rgba(0,0,0,.7);
}

.modal {
    box-shadow:0px 0px 30px 10px;
    border-radius:2px;
    padding:40px;
    position: fixed;
    top:calc(50% - 200px);
    left:calc(50% - 200px);
    height:464px;
    width:440px;
    align-items: center;
    justify-content: center;
    background:#fff;
}
button.modalClose {
    position: absolute;
    right: 40px;
    width: 36px;
}
.alternateModalLink {
    position:absolute;
    bottom:40px;
}
.buttons {
    margin-top:20px;
}