.App {
    height: 100%;
    min-height: 1500px;
    width: 100%;
    position: relative;
    font-family:'Montserrat'
}
p {
    margin:0px;
}
*, ::after, ::before {
    box-sizing: border-box;
}
.left {
    float:left;
}
.right {
    float:right;
}
button {
    background-color:#ADEFD1;
    padding: 10px;
    background-position: center;
    font-family: 'Montserrat';
    width:100px;
    font-weight:700;
    border: 0px;
    border-radius: 3px;
    color: #00203F;
    box-shadow: 0px 0px 24px -14px #10c6b7;
}
button:hover {
    color:#10C6B7;
    font-weight:700;
    transition:.2s;
}
.active-screen {
    width:734px;
    min-width:734px;
    max-width:734px;
    min-height:1000px;
}

.secondary-panel {
    width:40%;
    padding:15px;
    position:relative;
    display:inline-block;
}
.main-name-heading {
    font-size:40px;
    color:#00203F;
}

/* Montserrat main font */
/* Cursive font */
@import url('https://fonts.googleapis.com/css2?family=Cedarville+Cursive&family=Montserrat:wght@100;400;500;600;700;800;900&display=swap');
/* cyrillic-ext */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nYivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
}
/* cyrillic */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nRivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
}
/* greek */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nWivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0370-03FF;
}
/* latin-ext */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nbivNm4I81PZQ.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Press Start 2P';
    font-style: normal;
    font-weight: 400;
    src: url(https://fonts.gstatic.com/s/pressstart2p/v9/e3t4euO8T-267oIAQAu6jDQyK3nVivNm4I81.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
.capch {
    display:none;
}
.cursive {
    font-family: 'Cedarville Cursive', cursive;
}

.tagline {
    margin-top:10px;
    position: absolute;
    top: 24px;
    left: 522px;
}
