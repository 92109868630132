.secondary-header-panel {
    width:calc(100% - 736px);
    display:flex;
    float:right;
    height:100px;
    padding:15px;
    background-color:#fff;
    z-index:2;
    border-bottom:2px dotted #eaeaea;
    border-left:2px dotted #eaeaea;
    position:fixed;
    left:734px;
}