
.form label, .form input {
    float: left;
    clear: both;
    width: 100%;
}
.form label {
    width:25%;
}

.form label {
    font-size: 10px;
    text-transform: capitalize;
    color:#878787;
    display:inline-block;
    margin-bottom:5px;
    margin-top:5px;
}
.modalFormHeader h1 {
    margin-top:0px;
}
.signUpForm button {
    margin-right:10px;
}
.formError {
    display:inline-block;
    color:red;
    font-size:10px;
}
.formError::before {
    content:'*';
}
.form div {
    height:44px;
}