.header-profile-img {
    width:78px;
    height:78px;
}
.header-profile-img-wrapper {
    border:1px solid #00203F;
    border-radius:50%;
    overflow:hidden;
    width:70px;
    height:70px;
    background:#00203F;
    text-align:center;
}
.header-profile-email {
    font-size:8px;
    letter-spacing:1px;
}
.profile-img {
}
.profile-email {
}
.left-profile-wrapper {
    float:left;
}
.menu-login-wrapper {
    float:left;
    width:calc(100% - 120px);
    display:flex;
    align-items:center;
    height:100%;
    flex-wrap:nowrap;
    margin-left:40px;
}
.menu-login-wrapper button {
    font-size:10px;
    /* MINT_GREEN  */
    background:#ADEFD1;
    /* SAILOR_BLUE  */
    color:#00203F;
}
.menu-login-wrapper button:hover {
    color:#00203F;
    background:#FFF;
}
