.App-header {
    position:fixed;
    width:736px;
    display:inline-block;
    height:100px; /*Important for game math.*/
    padding:0px 40px; /*Important for game math.*/
    border-bottom: 2px dotted #eaeaea;
}
.top {
    height:100px;
}