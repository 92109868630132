.maps .levelCompletionCheck {
    align-self: center;
}
.maps .MuiAccordionSummary-content{
    display:flex;
    align-content: center;
}
.maps span, .maps p {
    display:inline-block;
}
.maps .item-level-name {
    font-size:14px;
    margin-left:5px;
}
.levelInstructionList{
    list-style-type:none;
    padding:20px;
    font-size:20px;
    margin:0px;
}
.levelText {
    position:absolute;
    padding:20px;
    top: calc(50% + 14px);
    background:#ADBEEF;
    height:50%;
    font-size:16px;
    width:100%;
}
.instruction {
    float: left;
    background: #fff;
    padding: 5px 15px;
    margin: 10px 7px;
}
.maps .MuiTypography-root {
    align-self: center;
    color:#00203F;
}

.maps .activeLevel {
    margin-top:100px;
}