.mainAppWrapper { /*main screen wrapper */
    margin:0 auto;
    display:flex;

}
.uiWrapper {
    position: absolute;
    top: 480px;
    display: flex;
    align-items: center;
}
.metrics {
    position:absolute;
    top:580px;
    display:flex;
}

.flexItem {
    padding:20px;
    min-width:120px;
}
.flexItem.flexItemLarge button{
    padding:20px;
}
.flexItem.first {
    padding-left:0px;
}